<template>
  <div class="btm_bottom">
    <div class="cen_box">
      <div class="cen_left">
        <div class="btm_top">
          <div class="ic_one"></div>
          <p class="phone">咨询电话</p>
          <div class="ic_one" style="margin-left: 8.75vw"></div>
          <p class="phone" style="font-weight: 400;color:#BFBFBF">邮编</p>
          <div class="ic_one" style="margin-left: 2.60417vw"></div>
          <p class="phone" style="font-weight: 400;color:#BFBFBF">业务邮箱</p>
          <div class="ic_one" style="margin-left: 4.84375vw"></div>
          <p class="phone" style="font-weight: 400;color:#BFBFBF">公司地址</p>
        </div>
        <div class="btm_btm">
          <p class="p_pho">400-0379-333</p>
          <p class="p_yb">473000</p>
          <p class="p_yx">dgtcz@163.com</p>
          <p class="p_dz">河南省南阳市宛城区城投集团光电产业园B区3-B号</p>
        </div>
      </div>
      <div class="cen_rig">
        <img src="../../../assets/imgs/ycm.png" alt="">
      </div>
    </div>
    <div class="cen_box1">
      河南羿赐智能科技有限公司 版权所有 备案号：<span style="color:#ffffff">豫ICP备2022016943号-1</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  inject: ["reload"],
  watch: {},
  // 组件初始化
  created() {},
  // 组件实例挂载完毕
  mounted() {},

  destroyed() {},
  // 组件方法
  methods: {},
};
</script>

<style scoped lang='less'>
.btm_bottom {
  color: #ffffff;
  border-top: 1px solid transparent;
  .cen_box {
    width: 1090px;
    height: 109px;
    //border: 1px solid goldenrod;
    margin: 30px auto;
    display: flex;
    //margin-top: 76px;
    .cen_left {
      width: 907px;
      height: 100%;
      //border: 1px solid gray;
      .btm_top {
        width: 100%;
        height: 28px;
        margin-top: 27px;
        display: flex;
        //border: 1px solid gray;
        div:nth-child(1) {
          background-image: url("../../../assets/imgs/qt/ic4.png");
          background-size: 100% 100%;
        }
        div:nth-child(3) {
          background-image: url("../../../assets/imgs/qt/ic3.png");
          background-size: 100% 100%;
          
        }
        div:nth-child(5) {
          background-image: url("../../../assets/imgs/qt/ic2.png");
          background-size: 100% 100%;
        }
        div:nth-child(7) {
          background-image: url("../../../assets/imgs/qt/ic1.png");
          background-size: 100% 100%;
        }
        .ic_one {
          width: 18px;
          height: 18px;
          margin-top: 5px;
          //border: 1px solid gray;
        }

        .phone {
          width: 64px;
          height: 28px;
          line-height: 28px;
          margin-left: 8px;
          white-space: nowrap;
          word-wrap: normal;
          word-break: keep-all;
          //border: 1px solid gray;
          font-size: 16px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 28px;
        }
      }
      .btm_btm {
        width: 100%;
        height: 28px;
        margin-top: 13px;
        display: flex;
        //border: 1px solid gray;
        .p_pho {
          width: 172px;
          height: 28px;
          font-size: 24px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 28px;
          white-space: nowrap;
          word-wrap: normal;
          word-break: keep-all;
          margin-left: 24px;
        }
        .p_yb {
          width: 50px;
          height: 28px;
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #dadada;
          line-height: 28px;
          white-space: nowrap;
          word-wrap: normal;
          word-break: keep-all;
          margin-left: 86px;
        }
        .p_yx {
          width: 102px;
          height: 28px;
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #dadada;
          line-height: 28px;
          white-space: nowrap;
          word-wrap: normal;
          word-break: keep-all;
          margin-left: 86px;
        }
        .p_dz {
          width: 316px;
          height: 28px;
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #dadada;
          line-height: 28px;
          white-space: nowrap;
          word-wrap: normal;
          word-break: keep-all;
          margin-left: 84px;
        }
      }
    }
    .cen_rig {
      width: 109px;
      height: 109px;
      margin-left: 53px;
      //margin-top: 49px;
      border: 1px solid gray;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .cen_box1 {
    width: 422px;
    height: 28px;
    //border: 1px solid goldenrod;
    margin: -10px auto;
    //margin-left: auto;
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    //font-weight: 600;
    color: #bfbfbf;
    line-height: 28px;
    white-space: nowrap;
    word-wrap: normal;
    word-break: keep-all;
  }
}
</style>