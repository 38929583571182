import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import htmlToExcel from './excel/htmlToExcel';//刚刚写好的那个js文件的路径
// Vue.prototype.htmlToExcels = htmlToExcel;


import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)


// import Vue from 'vue'
import vPlayBack from 'v-playback';
Vue.use(vPlayBack);

// import Vue from 'vue'
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)
// import Vant,{Lazyload} from 'vant';
// import 'vant/lib/index.css'
// Vue.use(Vant);
// Vue.use(Lazyload);

import '../src/assets/styles/font/log.css'
import './assets/css/index.css'
import './assets/iconfonts/iconfont.css'
// 进度条
import VueProgressBar from 'vue-progressbar'
Vue.use(VueProgressBar, {
  color: '#00FCFF',  // 进度条颜色
  failedColor: 'red', // 失败显示的颜色
  height: '2px'  // 进度条高度
})
// import './loader/style-px2rem-loader'


import '../untils/flexPC'
// import '../untils/rem.js'

import request from '../untils/request.js'
Vue.prototype.$axios = request


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);



Vue.config.productionTip = false

new Vue({ 
  router,
  store,
  render: h => h(App)
}).$mount('#app')
