<template>
  <div id="app">
    <!-- <keep-alive> -->
       <router-view v-if="isRouterAlive" />
    <!-- </keep-alive> -->
   
  </div>
</template>
<script>
export default {
  // 组件名称
  name: 'App',
  provide () {
    return {
      reload: this.reload
    }
  },

  data () {
    return {
      isRouterAlive: true
    }
  },
  // 组件初始化
  created () {
  },
  // 组件实例挂载完毕
  mounted () {
  },
  // 组件计算属性
  computed: {
  },
  // 组件方法
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style lang="less" scoped>
#app {
  width: 100%;
  height: 100%;
}
/deep/.el-dialog{
  left:50%;
  top:50%;
  transform: translate(-50% , -50%);
  margin: 0px !important;
}
</style>

