import Vue from 'vue'
import Vuex from 'vuex'
import program from  './modules/program.js'
import user from  './modules/user.js'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    program,
    user,
  },
  plugins: [
    // veux持久化配置
    createPersistedState({
      storage:window.localStorage,
      key: 'rabbitstore-client',
      paths: ['program','user']
    }),
    // createPersistedState({
    //   storage:window.sessionStorage,
    //   key: 'rabbitstore-client',
    //   paths: ['user','program']
    // })
  ]
})
