<template>
  <div class="box1">
    <!-- <div class="pox1"></div> -->
    <div class="pox">
      <div class="div_a">
        <!-- <span class="sp1"></span> -->
        <a :class="[state == 1 ? 'li_st' : 'li_xz']" href="#activeone"
          >公司简介</a
        >
      </div>
      <div class="div_a">
        <a :class="[state == 9 ? 'li_st' : 'li_xz']" href="#active">新闻动态</a>
      </div>
      <div class="div_a">
        <a :class="[state == 2 ? 'li_st' : 'li_xz']" href="#activetwo"
          >产品介绍</a
        >
      </div>
      <div class="div_a">
        <a :class="[state == 3 ? 'li_st' : 'li_xz']" href="#activethree"
          >产品应用</a
        >
      </div>
      <div class="div_a">
        <a :class="[state == 4 ? 'li_st' : 'li_xz']" href="#activefour"
          >系统架构</a
        >
      </div>
      <div class="div_a">
        <a :class="[state == 5 ? 'li_st' : 'li_xz']" href="#activefive"
          >智慧能源管控流程</a
        >
      </div>
      <div class="div_a" style="height: 2.29167vw">
        <a :class="[state == 6 ? 'li_st' : 'li_xz']" href="#activesix"
          >二次循环在变流量中 央空调系统中的应用</a
        >
      </div>
      <!-- <div class="div_a">
        <a :class="[state == 7 ? 'li_st' : 'li_xz']" href="#activeseven"
          >项目案例</a
        >
      </div> -->
      <div class="div_a">
        <a :class="[state == 8 ? 'li_st' : 'li_xz']" href="#activeeight"
          >返回顶部</a
        >
      </div>

      <!-- <ul>
        <li
          style="margin-top: 0.83333vw"
          :class="[state == 1 ? 'li_st' : 'li_xz']"
          @click="handeldw(1)"
        >
          公司简介
        </li>
        <li>
          <a :class="[state == 2 ? 'li_st' : 'li_xz']" href="#activetwo">产品介绍</a>
        </li>
        <li :class="[state == 3 ? 'li_st' : 'li_xz']" @click="handeldw(3)">
          产品应用
        </li>
        <li :class="[state == 4 ? 'li_st' : 'li_xz']" @click="handeldw(4)">
          系统架构
        </li>
        <li :class="[state == 5 ? 'li_st' : 'li_xz']" @click="handeldw(5)">
          智慧能源管控流程
        </li>
        <li
          style="height: 2.29167vw"
          :class="[state == 6 ? 'li_st' : 'li_xz']"
          @click="handeldw(6)"
        >
          二次循环在变流量中 央空调系统中的应用
        </li>
        <li :class="[state == 7 ? 'li_st' : 'li_xz']" @click="handeldw(7)">
          项目案例
        </li>
        <li :class="[state == 8 ? 'li_st' : 'li_xz']" @click="handeldw(8)">
          返回顶部
        </li>
      </ul> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: 0, //导航状态
      
      scroll: 1,
    };
  },
  inject: ["reload"],
  watch: {
    // scroll: {
    //   handler(newss, old) {
    //     console.log(newss, 222);
    //     this.scroll = newss;
    //     if (newss < 400) {
    //       this.state = 0;
    //     } else if (newss > 400 && newss < 1000) {
    //       this.state = 1;
    //     } else if (newss > 1000 && newss < 3100) {
    //       this.state = 2;
    //     } else if (newss > 3100 && newss < 4400) {
    //       this.state = 3;
    //     } else if (newss > 4400 && newss < 5000) {
    //       this.state = 4;
    //     } else if (newss > 5000 && newss < 5500) {
    //       this.state = 5;
    //     } else if (newss > 5500 && newss < 6000) {
    //       this.state = 6;
    //     } else if (newss > 6000) {
    //       this.state = 7;
    //     }
    //   },
    //   immediate: false,
    // },
    scroll: {
      handler(newss, old) {
       
      },
      immediate: false,
    },
  },
  // 组件初始化
  created() {},
  // 组件实例挂载完毕
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  // 组件方法
  methods: {
    // handeldw(type) {
    //   this.state = type;
    //   if (type == 1) {
    //     document.body.scrollTop = 400;
    //     document.documentElement.scrollTop = 400;
    //   } else if (type == 2) {
    //     document.body.scrollTop = 1000;
    //     document.documentElement.scrollTop = 1000;
    //   } else if (type == 3) {
    //     document.body.scrollTop = 3100;
    //     document.documentElement.scrollTop = 3100;
    //   } else if (type == 4) {
    //     document.body.scrollTop = 4400;
    //     document.documentElement.scrollTop = 4400;
    //   } else if (type == 5) {
    //     document.body.scrollTop = 5000;
    //     document.documentElement.scrollTop = 5000;
    //   } else if (type == 6) {
    //     document.body.scrollTop = 5500;
    //     document.documentElement.scrollTop = 5500;
    //   } else if (type == 7) {
    //     document.body.scrollTop = 6000;
    //     document.documentElement.scrollTop = 6000;
    //   } else if (type == 8) {
    //     document.body.scrollTop = 0;
    //     document.documentElement.scrollTop = 0;
    //   }
    // },
    ccc() {
      document.body.scrollTop = 100;
      document.documentElement.scrollTop = 100;
      // console.log("cccc");
    },
    handleScroll(e) {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      //当前滚动的距离
      var current = document.documentElement.scrollTop;
      //页面的总高度
      var offsetHeight = document.documentElement.offsetHeight;
      //页面的可视高度
      var clientHeight = document.documentElement.clientHeight;
      //页面的总宽度
      var offsetWidth = document.documentElement.offsetWidth;
      //页面的可视高度
      // var clientHeight = document.documentElement.clientHeight;
      // console.log(this.scroll , current , '当前滚动的距离' , offsetHeight , '页面的总高度', clientHeight , '页面的可视高度' , offsetWidth , '宽度');
    },
  },
};
</script>

<style scoped lang='less'>
.box1 {
 
  // width: 100%;
  // height: 100%;
  // background-color: #ffffff;
  // position: fixed;
  // left: 10px;
  // top: 300px;
  //border: 1px solid gold;
  .pox {
    // width: 100%;
    // height: 100%;
    .div_a:hover{
      border-left: 4px solid #0072ff;
      a{
        color: #0072ff;
      }
    }
    .div_a {
      list-style: none;
      width: 130px;
      height: 22px;
      line-height: 22px;
      //padding-left: 16px;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #3d485d;
      // border: 1px solid gray;
      margin-top: 22px;
      cursor: pointer;
      // display: flex;
      // .sp1{
      //   width: 4px;
      //   height: 22px;
      //   border: 1px solid gold;
      // }
      a:hover {
        // color: #0072ff;
        // border-left: 4px solid #0072ff;
        font-weight: 600;
      }
      a {
        width: 83%;
        height: 100%;
        // border: 1px solid gold;
        display: block;
        text-decoration: none;
        position: absolute;
        left: 9%;
        color: #333;
      }
    }

    // ul li {
    //   list-style: none;
    //   width: 130px;
    //   height: 22px;
    //   line-height: 22px;
    //   padding-left: 16px;
    //   font-size: 14px;
    //   font-family: PingFangSC, PingFang SC;
    //   font-weight: 400;
    //   color: #3d485d;
    //   // border: 1px solid gray;
    //   margin-top: 22px;
    //   cursor: pointer;
    //   a {
    //     width: 100%;
    //     height: 100%;
    //     border: 1px solid gold;
    //     display: block;
    //     text-decoration: none;
    //     color: #333;
    //   }
    // }
    // li:hover {
    //   color: #0072ff;
    //   font-weight: 600;
    // }
    // .li_st {
    //   list-style: none;
    //   width: 100px;
    //   height: 22px;
    //   line-height: 22px;
    //   padding-left: 16px;
    //   font-size: 14px;
    //   font-family: PingFangSC, PingFang SC;
    //   font-weight: 400;
    //   color: #0072ff;
    //   border-left: 4px solid #0072ff;
    //   // border: 1px solid gray;
    //   font-weight: 600;
    //   // margin-top: 22px;
    //   cursor: pointer;
    // }
    // .li_xz {
    //   list-style: none;
    //   width: 120px;
    //   height: 22px;
    //   line-height: 22px;
    //   padding-left: 16px;
    //   font-size: 14px;
    //   font-family: PingFangSC, PingFang SC;
    //   font-weight: 400;
    //   color: #3d485d;
    //   border: 1px solid gray;
    //   // margin-top: 22px;
    //   cursor: pointer;
    // }
  }
}
</style>