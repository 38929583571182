import axios from 'axios'
import { Message } from 'element-ui';
import store from '../src/store/index'
import router from '../src/router/index'
var baseURL = '';
if (process.env.NODE_ENV == 'production') {
  baseURL = process.env.VUE_APP_BASE_API
} else {
  baseURL = process.env.VUE_APP_BASE_API
}

//1. 创建新的axios实例，
const request = axios.create({
  // baseURL,
  // baseURL: "/api",
  // timeout: 3 * 1000
})
// 2.请求拦截器
request.interceptors.request.use(config => {

  // if (config.url == '/login') { } else {
  //   // 没有登录用的是固定的token
  //   //var  token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE3MTA0MTk1NjcsInVzZXJuYW1lIjoiYWRtaW4ifQ.utyjD3gFZ1JwQTC-ziI1QHSIkZwhoCq7smmPM6Z68nE"
  //   // var token = store.getters.getUser.token
  //   // var username = localStorage.getItem("username")
  //   // var phone = localStorage.getItem("phone")
  //   config.headers = {
  //     // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8', //配置请求头
  //     // 'Authorization': token
  //     'X-Access-Token': token,
  //     // 'username': username,
  //     // 'phone' : phone,
  //     //'X-Access-Token': 'fff'
  //   }
  // }

  return config


}, error => {
  Promise.reject(error)
})
//响应拦截器
request.interceptors.response.use(response => {
  console.log(response.data, "响应拦截")
  if (response.status == 200) {
    return response
  }
  if (response.data.code === 401) {
    Message({
      type: "info",
      message: "token失效，请重新登录!",
    });
    router.push("/login");
  } else if (response.data.code === 200) {
    return response
  } else {
    Message({
      type: "info",
      message: response.data.msg + '请重试',
    });
  }
  // return response
},
  error => {
    console.log(error, '响应报错')
    if (error.response.data.code === 401) {
      Message({
        type: "info",
        message: "token失效，请重新登录!",
      });
      router.push("/login");
    }
    //return Promise.reject('error');
  })

export default request

