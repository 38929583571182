import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

// import Home from '../views/Home.vue'
import Index from "../views/index.vue";
//import Air from '../views/home/air.vue'
//import Actc from '../views/home/actc.vue'
//import Overview from '../views/home/overview/Overview'

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/login',
  //   component: () => import('../views/login')
  // },
  {
    path: "/",
    component: Index,
    children: [
      {
        path: "",
        component: () => import("../views/home/home.vue"),
      },
      {
        path: "/home",
        component: () => import("../views/home/home.vue"),
      },
      {
        path: "/activeone",
        component: () => import("../views/home/home.vue"),
      },
      {
        path: "/active",
        component: () => import("../views/home/home.vue"),
      },
      {
        path: "/activetwo",
        component: () => import("../views/home/home.vue"),
      },
      {
        path: "/activethree",
        component: () => import("../views/home/home.vue"),
      },
      {
        path: "/activefour",
        component: () => import("../views/home/home.vue"),
      },
      {
        path: "/activefive",
        component: () => import("../views/home/home.vue"),
      },
      {
        path: "/activesix",
        component: () => import("../views/home/home.vue"),
      },
      {
        path: "/activeseven",
        component: () => import("../views/home/home.vue"),
      },
      {
        path: "/activeeight",
        component: () => import("../views/home/home.vue"),
      },
      {
        path: "/activeeightt",
        component: () => import("../views/home/home.vue"),
      },
      {
        path: "/more",
        name: "/more",
        component: () => import("../views/more/more.vue"),
      },
      {
        path: "/newsxq",
        name: "/newsxq",
        component: () => import("../views/newsxq/newsxq.vue"),
      },
      {
        path: "/recruit",
        name: "/recruit",
        component: () => import("../views/recruit/recruit.vue"),
      },
      
    ],
  },
  // {
    // path: '/more',
    // name:'/more',
    // component: () => import('../views/more/more.vue')
  // },
];

// 新添加的-解决导航重复点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  routes,
});

export default router;
