<template>
  <div class="index_container">
    <!-- <div class="body"> -->
    <div class="title">
      <div class="left"></div>
      <div class="left-size"></div>
      <div class="cen">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          active-text-color="#409EFF"
          @select="handleSelect"
        >
          <el-menu-item index="/home">首页</el-menu-item>
          <el-menu-item @click="handelal">客户案例</el-menu-item>
          <el-menu-item @click="handelal">联系我们</el-menu-item>
          <el-menu-item index="/more"></el-menu-item>
          <el-menu-item index="/newsxq"></el-menu-item>
          <el-menu-item index="/recruit">人才招聘</el-menu-item>
        </el-menu>
      </div>
      <!-- <div class="btn" @click="handellogin">登 录</div> -->
      <div class="btn" @click="handellogin">
        <p class="spn1"></p>
        <p class="spn2">登 录</p>
      </div>
    </div>
    <div class="yc_cen">
      <router-view :scroll="scroll" @passfunction="meetfunction"></router-view>
    </div>
    <!--  -->
    <!-- <div class="btm_bottom"><Contact></Contact></div> -->
    <!-- <div class="swp"></div>
      <h1 class="p_tit" id="activeone">公司简介</h1>
      <div class="brief_introduction">公司</div>
      <h1 class="p_tit" id="activetwo" style="padding-top: 3.125vw; padding-bottom: 0.52083vw">
        产品介绍
      </h1>
      <div class="product">产品</div>
      <h1 class="p_tit1" >机房 BIM 设计</h1>
      <div class="video">视频</div>
      <h1 class="p_tit1" id="activethree">产品应用</h1>
      <div class="application">应用</div>
      <h1 class="p_tit1" id="activefour">系统架构</h1>
      <div class="architecture">架构</div>
      <h1 class="p_tit1" id="activefive">智慧能源管控流程</h1>
      <div class="architecture">架构</div>
      <h1 class="p_tit1" id="activesix">二次循环在变流量中央空调系统中的应用</h1>
      <div class="application">应用</div>
      <div class="btm" id="activeseven"></div>
      <div class="box"><Navigati></Navigati></div> -->
    <!-- </div> -->
    <!-- <div class="box"><Navigati></Navigati></div> -->

    <!-- 官网登录 39.164.86.18：10000 -->
  </div>
</template>

<script>
import { getprojectListByUser, getProjectIds } from "../assets/api/index";
import { mapState, mapMutations, mapActions } from "vuex";
import Navigati from "../components/Navigati.vue";
import Contact from "../views/home/components/Contact.vue";
// import Title from "../components/Title.vue";
export default {
  // 组件名称
  name: "Index",
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  components: { Navigati, Contact },
  // 组件状态值
  data() {
    return {
      activeIndex: "/home",
      scroll: 1,
      urlname: "",
    };
  },
  inject: ["reload"],
  watch: {
    urlname: {
      handler(newss, old) {
        console.log(newss , '9999999')
        if (newss == "more") {
          this.activeIndex = "/more";
        } else if (newss == "wsxq") {
          this.activeIndex = "/newsxq";
        }else if(newss == "ruit"){
          this.activeIndex = "/recruit";
        } else {
          this.activeIndex = "/home";
        }
        // this.activeIndex = newss
        //console.log(newss , 1111);
      },
      immediate: false,
    },
  },
  // 组件初始化
  created() {},
  // 组件实例挂载完毕
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  computed: {},
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  // 组件方法
  methods: {
    handelal() {
      document.body.scrollTop = 10000;
      document.documentElement.scrollTop = 10000;
    },
    meetfunction(val) {
      var index = "";
      var arr = val;
      // this.activeIndex = val
      index = arr.substr(-4);
      this.urlname = index;
    },

    handellogin() {
      var url = "http://39.164.86.18:10000/";
      window.open(encodeURI(url));
    },
    handleSelect(key, keyPath) {
      //console.log(key, keyPath);
      this.$router.push(key);
    },
    handleScroll() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
    },
  },
};
</script>
 
<style scoped lang='less'>
// .btm_bottom{
//       width: 100%;
//       height: 214px;
//       background-color: #101820;
//       position: relative;
//       bottom: 0px;
//     }
.tervie {
  width: 100%;
  height: 500px;
  border: 1px solid gold;
}
// .body::-webkit-scrollbar {
//   display: none;
// }
.index_container {
  width: 99.85%;
  height: 100%;
  //position:absolute;
  background-color: #f9f9f9;
  // .body {
  //   width: 100%;
  //   //height: 11233px;
  // overflow: hidden;
  // overflow-y: scroll;
  // overflow-x: hidden;
  // overflow-y: scroll;
  //overflow-x: scroll;
  //   background-color: #f9f9f9;
  //   color: #1b1e20;
  .box {
    width: 190px;
    //height: 460px;
    //background-color: #ffffff;
    position: fixed;
    //left: 10px;
    top: 300px;
    //border: 1px solid gold;
  }
  .title {
    width: 100%;
    min-height: 80px;
    display: flex;
    position: fixed;
    top: 0;
    //left: 10px;
    //top: 300px;
    z-index: 999;
    background-color: #ffffff;
    box-shadow: 0px 2px 29px 0px rgba(0, 0, 0, 0.12);
    //background-color: goldenrod;
    .left {
      width: 60px;
      height: 60px;
      background-image: url("../assets/imgs/qt/logo.png");
      background-size: 100% 100%;
      margin-top: 10px;
      margin-left: 80px;
    }
    .left-size {
      width: 319px;
      height: 34px;
      font-size: 28px;
      font-family: FZZ;
      font-weight: 600;
      color: #333333;
      line-height: 34px;
      margin-top: 23px;
      margin-left: 18px;
      white-space: nowrap;
      background-image: url("../assets/imgs/ht/titlename.png");
      background-size: 100% 100%;
      //   border: 1px solid gold;
    }
    .cen {
      width: 600px;
      height: 79px;

      // border: 1px solid gray;
      margin-left: 240px;
      /deep/.el-menu-demo {
        height: 79px !important;
        background-color: #ffffff;
      }
      /deep/li {
        line-height: 80px;
        height: 80px !important;
        font-size: 18px;
        //font-weight: 900;
      }
      li:nth-child(2) {
        margin-left: 50px;
      }
      li:nth-child(3) {
        margin-left: 50px;
      }
      li:nth-child(4) {
        width: 1px;
        display: none;
      }
      li:nth-child(5) {
        width: 1px;
        display: none;
      }
      li:nth-child(6) {
        margin-left: 50px;
      }
      //一定要添加！important提高优先级
      .el-menu-item.is-active {
        //设置背景颜色
        background-color: #ffffff !important;
        //设置字体颜色
        color: #0076ff;
        font-weight: 900;
        border-bottom-color: transparent !important;
      }
      .el-menu-item:hover {
        background-color: #ffffff;
        color: #0076ff;
        font-weight: 900;
        border-bottom-color: transparent !important;
      }
    }
    .btn {
      width: 178px;
      height: 80px;
      line-height: 80px;
      text-align: center;
      background: linear-gradient(150deg, #00abff 0%, #0072ff 100%);
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      // margin-left: 28.6%;

      position: absolute;
      right: 0.15%;
      cursor: pointer;
      // display: flex;
      .spn1 {
        width: 32px;
        height: 32px;
        background-image: url("../assets/imgs/icc.png");
        background-size: 100% 100%;
        position: absolute;
        top: 23px;
        left: 51px;
        // display: inline-block;
        // border: 1px solid gold;
        // margin-top: 24px;
      }
      .spn2 {
        // height: 32px;
        position: absolute;
        top: 0px;
        left: 91px;
        // display: inline-block;
        // border: 1px solid gold;
        // margin-top: 20px;
      }
    }
  }
  .yc_cen {
    // margin-top: 80px;
  }
}

// }
</style>


