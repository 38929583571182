const user = {
    state: {
        userInfo: {
            userId: 1,
            token: '',
            rw: null,
        }
    },
    getters: {
        getUser(state) {
            return state.userInfo
        }
    },
    mutations: {
        SET_USERINFO(state, userInfo) {
            state.userInfo = userInfo
            // var n = Math.random()>0.5?1:0
            // state.rw = n
        }
    },
    actions: {
        setUerInfo({ commit }, userInfo) {
            commit('SET_USERINFO', userInfo)
        }
    }
}
export default user