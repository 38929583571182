const program = {
    state:{
        proId:'1',
        projectList:null,
    },
    getters:{
        getProID(state){
            return state.proId 
        }
    },
    mutations:{
        SET_PROID(state,id){
            state.proId  = id
        },
        SET_PROJECT(state,prolist){
            state.projectList  = prolist
        },
    },
    actions:{
        setProId({ commit },id){
            commit('SET_PROID',id)
        },
        setProject({ commit },prolist){
            commit('SET_PROJECT',prolist)
        },
    }
}
export default program